document.addEventListener('DOMContentLoaded', (event) => {
    // The function actually applying the offset
    function offsetAnchor() {
        if (location.hash.length !== 0) {
            const target = document.querySelector(location.hash);
            if (target) {
                const targetOffset = target.getBoundingClientRect().top + window.scrollY;
                window.scrollTo(window.scrollX, targetOffset - 150);
            }
        }
    }

    // Captures click events of all <a> elements with href starting with #
    document.querySelectorAll('a[href^="#"]').forEach(anchor => {
        anchor.addEventListener('click', function(e) {
            // Prevent default anchor click behavior
            e.preventDefault();

            // Change the hash manually to avoid default jump
            history.pushState(null, null, this.getAttribute('href'));

            // Apply the offset
            offsetAnchor();
        });
    });

    // Set the offset when entering page with hash present in the URL
    window.setTimeout(offsetAnchor, 0);
});