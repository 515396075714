// Enable AOS - documentation: https://michalsnik.github.io/aos/

/** IMPORTANT **
 *
 * Use AOS declarations in class="" instead of data-aos="" - this is added to make AOS.js friendly for bootstrap gutenberg blocks.
 *
 * e.g. class="data-aos_fade-in" replaces data-aos="fade-in"
 *
 * the element MUST have class "aos-animate" added to trigger
 *
 */


function ismatch(str){
    var ret = null;
    var tab = ['data-aos_', 'data-aos-delay_', 'data-aos-duration_', 'data-aos-easing_'];
    Object.values(tab).forEach( function (value) {
        if (String(str).match(value)){
            ret = str.split('_');
            return false;
        }
    });
    return ret;
}

$(function() {
    $('.aos-animate').each(function () {
        var $this = $(this);
        var tab = $this.attr('class').split(' ');
        var keep;
        Object.values(tab).forEach(function (item) {
            var ello = ismatch(item);
            if (ello !== null){
                $this.attr(ello[0], ello[1]);
            }
        });

    });
    AOS.init({
        duration: 900,
        offset: 200,
    });
});
