$('.toggle-title').click(function () {
    if ($(this).hasClass('active')) {
        $(this).removeClass('active').attr('aria-expanded', 'false').siblings('.toggle-item').slideToggle().attr('aria-hidden', 'true');
    } else {
        $(this).addClass('active').attr('aria-expanded', 'true').siblings('.toggle-item').slideToggle().attr('aria-hidden', 'false');
    }
});

$('.helper-tooltip').click(function () {
    $('.helper-text').fadeToggle();
});