var header = $("#header"); // Header
var mainMenu = $("#main-menu"); // Main Menu
var siteMenu = $("#site-menu"); // Site Menu Container
var browserWindow = $(window); // Window Height

if ($(".menu-toggle").length) {
  document.querySelector(".menu-toggle").addEventListener("click", function () {
    $("#pull-menu").toggleClass("fixed").toggleClass("is-active");
    $("#site-menu").toggleClass("is-active");

    if ( $("#site-menu").hasClass('is-active') ){
      $(this).attr('aria-expanded', 'true');
      $(this).children('.sr-only').html('close main navigation menu');
      $('#site-menu').attr('aria-hidden', 'false').find('a:not(#mobile-branding)').attr('tabindex', '0');
    } else {
      $(this).attr('aria-expanded', 'false');
      $(this).children('.sr-only').html('open main navigation menu');
      $('#site-menu').attr('aria-hidden', 'true').find('a:not(#mobile-branding)').attr('tabindex', '-1');
    }
  });
}

$('#footer-and-mobile-menu > li.menu-item-has-children > a').click(function(e){
  e.preventDefault();
  e.stopPropagation();
  $('#footer-and-mobile-menu > li > ul.sub-menu').not($(this).siblings()).slideUp();
  $('#footer-and-mobile-menu > li.sub-menu-active > a').not($(this)).attr('aria-expanded', 'false').parent().removeClass('sub-menu-active');
  $(this).siblings('.sub-menu').slideToggle().parent().toggleClass('sub-menu-active');

  if ( $(this).parent().hasClass('sub-menu-active') ){
    $(this).attr('aria-expanded', 'true');
  } else {
    $(this).attr('aria-expanded', 'false');
  }
});

$('#footer-and-mobile-menu > li > ul > li.menu-item-has-children > a').click(function(e){
  e.preventDefault();
  e.stopPropagation();
  $('#footer-and-mobile-menu > li > ul > li > ul.sub-menu').not($(this).siblings()).slideUp();
  $('#footer-and-mobile-menu > li > ul > li.sub-menu-active > a').not($(this)).attr('aria-expanded', 'false').parent().removeClass('sub-menu-active');
  $(this).siblings('.sub-menu').slideToggle().parent().toggleClass('sub-menu-active');

  if ( $(this).parent().hasClass('sub-menu-active') ){
    $(this).attr('aria-expanded', 'true');
  } else {
    $(this).attr('aria-expanded', 'false');
  }
});

$(function(){

  $('#mobile-search-container label[for="globalSearch"]').attr('for', 'global-search').attr('id', 'global-search-label');
  $('#mobile-search-container input#globalSearch').attr('id', 'global-search');

  if ( $(window).width() < 992 ){
    $('#mobile-nav a').attr('tabindex', '-1');
  }
  //setting submenu ids and attributes for mobile menu
  $('#footer-and-mobile-menu li.menu-item-has-children').each(function(){
    var famsubmenuID = $(this).attr('id');
    $(this).children('.sub-menu').attr('id', 'famm-' + famsubmenuID).attr('aria-hidden', 'true'); 
  });
  $('#footer-and-mobile-menu li.menu-item-has-children > .sub-menu').each(function(){
    var famsubmenu = $(this).attr('id');
    $(this).siblings('a').attr('role', 'button').attr('aria-haspopup', 'true').attr('aria-controls', famsubmenu).attr('aria-expanded', 'false');
  });

  //setting submenu ids and attributes for footer menu
  $('#footer-menu > li > a').attr('tabindex', '-1').attr('aria-expanded', 'true').attr('role', 'presentation');
  $('#footer-menu > li > ul > li.menu-item-has-children').each(function(){
    var submenuID = $(this).attr('id');
    $(this).children('.sub-menu').attr('id', 'fm-' + submenuID).attr('aria-hidden', 'true');
  });
  $('#footer-menu > li > ul > li.menu-item-has-children > .sub-menu').each(function(){
    var submenu = $(this).attr('id');
    $(this).siblings('a').attr('role', 'button').attr('aria-haspopup', 'true').attr('aria-contrls', submenu).attr('aria-expanded', 'false');
  });
  
  //setting submenu ids and attributes for mega menu
  $('#main-mega-menu > li.has-mega-menu > .mega-menu').each(function(){
    var megamenuID = $(this).attr('id');
    $(this).attr('aria-hidden', 'true').siblings('a').attr('role', 'button').attr('aria-haspopup', 'true').attr('aria-controls', megamenuID).attr('aria-expanded', 'false');
  });
});

/**
 * 
 * Mega menu functionality 
 * 
 */
 $(function(){
  var timeout;
  // $(".mega-menu-navigation > li.has-mega-menu").hover(function (e) {
  //     e.preventDefault();
  //     e.stopPropagation();
  //     $(this).toggleClass('active').children('.mega-menu').stop(true, false).fadeToggle().toggleClass('active');
  //     $(this).siblings().removeClass('active').children('.mega-menu').fadeOut().removeClass('active');
  //     if ( $(this).hasClass('active') ){
  //       $(this).children('a').attr('aria-expanded', 'true').siblings('.mega-menu').attr('aria-hidden', 'false');
  //     } else {
  //       $(this).children('a').attr('aria-expanded', 'false').siblings('.mega-menu').attr('aria-hidden', 'true');
  //     }
  // });
  $(".mega-menu-navigation > li.has-mega-menu > a").click(function (e) {
    e.preventDefault();
    e.stopPropagation();
    $(this).parent().toggleClass('active').children('.mega-menu').stop(true, false).fadeToggle().toggleClass('active');
    $(this).parent().siblings().removeClass('active').children('.mega-menu').fadeOut().removeClass('active');

    if ( $(this).parent().hasClass('active') ){
      $(this).attr('aria-expanded', 'true').siblings('.mega-menu').attr('aria-hidden', 'false');
    } else {
      $(this).attr('aria-expanded', 'false').siblings('.mega-menu').attr('aria-hidden', 'true');
    }

  });
  $('body').click(function(event){
    // Check if the clicked element is not within the mega menu
    if (!$(event.target).closest('.mega-menu').length) {
        // Check if mega menu is active before closing
        if ($('.mega-menu-navigation > li.has-mega-menu.active').length > 0) {
            $('.mega-menu-navigation > li.has-mega-menu.active').removeClass('active').children('.mega-menu').fadeOut().removeClass('active');
        }
    }
  });
});


/**
 * 
 * Search
 * 
 */
$("#search-container input").on("blur", function () {
  $("#navigation-container").removeClass("search-open");
});

$("#above-menu-header .search-icon").on("click", function (e) {
  e.preventDefault();
  e.stopPropagation();

  $("#navigation-container").toggleClass("search-open");
  $("#search-container input").focus();
});

$('.close-search').click(function(){
  $("#navigation-container").removeClass("search-open");
});


$("#mobile-nav .search-icon").on("click", function (e) {
  e.preventDefault();
  e.stopPropagation();
  $(this).toggleClass('search-active');
  $("#mobile-search-container").fadeToggle();
  $("#mobile-search-container input").focus();
});



/**
 * 
 * Footer
 * 
 */
$('#footer-menu .menu-item-has-children > a').on('click', function (e) {
  if ($(this).parent().hasClass('active')) {
    $(this).parent().removeClass('active');
    $(this).attr('aria-expanded', 'false').parent().find('.sub-menu').attr('aria-hidden', 'true').slideToggle();
    e.preventDefault();
    e.stopPropagation();
    return;
  } else {
    // close others
    $('#footer-menu .menu-item-has-children.active > a').attr('aria-expanded', 'false').siblings('.sub-menu').attr('aria-hidden', 'true').slideToggle();
    $('#footer-menu .menu-item-has-children')
      .not($(this).parent())
      .removeClass('active');
    e.preventDefault();
    e.stopPropagation();
    $(this).parent().toggleClass('active');
    $(this).attr('aria-expanded', 'true').parent().find(".sub-menu").attr('aria-hidden', 'false').slideToggle();
  }
});

// document ready
$(document).ready(function () {

  $('li.menu-item.current-menu-item').parents('li.has-mega-menu').addClass('mega-menu-current');


  // Hide Header on on scroll down
  var didScroll;
  var lastScrollTop = 0;
  var delta = 5;
  var navbarHeight = $("#navigation-container").outerHeight();

  $(window).scroll(function (event) {
    didScroll = true;
  });

  setInterval(function () {
    if (didScroll) {
      hasScrolled();
      didScroll = false;
    }
  }, 250);

  function hasScrolled() {
    var st = $(window).scrollTop();

    // Make sure they scroll more than delta
    if (Math.abs(lastScrollTop - st) <= delta) return;

    // If they scrolled down and are past the navbar, add class .nav-up.
    // This is necessary so you never see what is "behind" the navbar.
    if (st > lastScrollTop && st > navbarHeight) {
      // Scroll Down
      $("#navigation-container").removeClass("nav-down").addClass("nav-up");
      $("body").removeClass("nav-down").addClass("nav-up");
    } else {
      // Scroll Up
      if (st + $(window).height() < $(document).height()) {
        $("#navigation-container").removeClass("nav-up").addClass("nav-down");
        $("body").removeClass("nav-up").addClass("nav-down");
      }
    }

    lastScrollTop = st;
  }
});
